html,
body,
div#root {
  height: 100%;
}

a,
a:hover {
  text-decoration: none !important;
}

// START: For header
.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.valign_wrapper_flex_mob {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.user-info {
  //margin-top: -13px;
  .admin-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notification-icon {
    .badge {
      position: relative;
      top: -15px;
      left: -5px;
      /* border: 1px solid black; */
      border-radius: 50%;
      background-color: $color2;
      width: 23px;
      font-family: $base-font1;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
      color: $white-color;
    }
  }
  .user-info-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

// END : header

.custom-table-view {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  width: 100%;
  @include onMediumScreen {
    padding: 15px 10px;
  }
  .content-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid $light-blue;
    padding-top: 0;
    .header-btn {
      align-self: center;
    }
  }
}

.recent-messages {
  display: flex;
  flex-direction: column-reverse;
}

.header-title {
  @include setMainHeading;
}
// START :: LOGIN CSS START
// .login-left {}
.left-img {
  max-height: 100%;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  //object-position: top;
}
.login-left {
  width: 20%;
  flex: 0 0 27.83333%;
  max-width: 27.83333%;
}

.log-wrapper-left {
  position: absolute;
  top: 0;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 414px;
  width: 80%;
  @include onOnlyThisWidth(1024px) {
    margin-left: 5%;
  }
  @include onOnlyThisWidth(768px) {
    margin-left: 6%;
  }
}

.mask-login-left {
  width: 130%;
  height: auto;
}

.login-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 20%;
  margin: auto;
  max-width: 70%;

  // @include onMobileScreen{
  //     margin-left: 25%;
  // }
  // @include onOnlyThisWidth(1024px){
  //     margin-left: 70%;
  // }
}

.custom-form {
  max-width: 500px;
  height: 100%;
  width: 100%;
}

.form-signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

// START :: Therapist List screen
.therapist {
  .user-info .admin-name {
    justify-content: flex-start;
    img {
      @include setThumbinail;
    }
  }
  .view-all-link {
    color: black;
    font-family: $base-font1;
    font-size: 0.9375rem;
    font-weight: 501;
    line-height: 0.63;
  }
  thead {
    background-color: $color4;
    th {
      background: #e8f1fe;
      font-family: $base-font1;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2;
      text-align: left;
      color: #717171;
      @include onSmallScreen {
        font-size: 1rem;
      }
      @include onMobileScreen {
        font-size: 0.625rem;
      }
    }
  }
}

.therapist-detail {
  thead {
    background-color: $color4;
    th {
      background: #e8f1fe;
      padding: 2rem 0.75rem;
    }
  }
}

// END :: Therapist List screen
// START ::
.table-billing-info {
  tr td span {
    font-family: $base-font1;
    line-height: 2.1;
    color: $color1;
  }
  .table-nm {
    font-weight: 300;
    font-size: 1.25rem;
  }
  .table-bold-text {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .dropdown-menu {
    .dropdown-item {
      font-family: $base-font1;
      font-size: 1.0625rem;
      line-height: 2.41;
      color: #3674e8;
      height: 20px;
      margin: 0px 0 0 21px;
      padding: 0px;
      text-align: center;
      align-items: center;
      display: flex;
      background: none;
      height: 30px;
    }
  }
  .dropleft {
    z-index: 999;
    .dropdown-toggle {
      border: none;
      background: none;
    }
    .button {
      border: none;
    }
    .dropdown-toggle::before,
    .dropdown-toggle:active,
    .dropdown-toggle:focus {
      border: none;
    }
  }
}

// Add Therpist CSS
.addtheripist {
  margin-top: 0px;
  width: 100%;
  max-width: 1010px;
  padding: 20px;
  .conetent-title {
    @include setMainHeading;
  }
  .fileuplaod {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 259px;
    border: solid 2px $light-blue;
    background-color: $body-bg;
    input[type="file"] {
      opacity: 0;
      position: Absolute;
    }
    img {
      height: 170px;
    }
  }
  .content-box {
    text-align: center;
  }
  .file-title {
    margin-top: 10px;
    color: $color1;
    font-family: $base-font1;
    font-size: 1.375rem;
  }
  .file-name {
    font-size: 0.75rem;
    margin-top: 15px;
  }
}

.date-radio {
  position: relative;
  padding: 11px;
  width: 65px;
  height: 70px;
  border-radius: 6px;
  /* background-color: $color2; */
  color: $color2 !important;
  font-size: 1.125rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex: 1;
  padding: 0;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 70px;
    cursor: pointer;
    &:checked {
      & + .days {
        width: 100%;
        height: 70px;
        border-radius: 6px;
        background-color: $color2;
        margin: -12px;
        color: white;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.working-days-checkbox {
  position: relative;
  padding: 0px;
  // width: 65px;
  height: 46px;
  border-radius: 6px;
  /* background-color: $color2; */
  color: $color2 !important;
  font-size: 1.125rem !important;
  // display: flex;
  justify-content: center;
  align-items: center;
  // margin: 10px;
  .days {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 96px;
    height: 46px;
    cursor: pointer;
    &:checked {
      & + .days {
        // width: 65px;
        height: 46px;
        border-radius: 6px;
        background-color: $color2;
        // margin: -12px;
        color: white;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.custom-table .lang {
  font-family: $base-font1;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: $color1;
}

.selectd-date-text {
  font-family: $base-font1;
  font-size: 1.25rem;
  line-height: 1.3;
  color: $color1;
  font-weight: normal;
  @include onSmallScreen {
    font-size: 1rem;
  }
}

// }
// .form-signin-wrapper form .input-data label{
//     position: absolute;
//     font-weight: normal;
//     font-size: medium;
//     left:380px;
//     margin-top: -40px;
//     bottom: auto;
//     color: #999;
.input-data input:focus ~ label {
  transform: translateY(-20px);
  color: #999;
  font-size: small;
}

.NoPath---Copy-46 {
  width: 196px;
  height: 191px;
  margin: 35px 48px 64px 3px;
  object-fit: contain;
  border-radius: 317px;
}

//Settings
.green-submit-button {
  width: 304px;
  height: 60px;
  padding: 12px 111px 14px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177);
  font-family: $base-font1;
  font-size: 1.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: $white-color;
}

//details
.name {
  font-weight: 500;
}

.casesnotes {
  width: 1364px;
  height: 160px;
  margin: 40.5px 57px 45px 18px;
  padding: 28px 38px 38px 34px;
  background-color: $bg-color2;
  border-radius: 10px;
}

.detailsvideo {
  margin-left: 62%;
}

// }
// .input-data input:focus ~ label{
//     transform: translateY(-20px);
//     color: #999;
//     font-size: small;
// }
//TherapistDashboard
.seperator {
  border-left: 2px solid $white-color;
  height: 60px;
  opacity: 0.2;
}

.seperator1 {
  border-left: 2px solid $bg-color2;
  height: auto;
}

.font-content {
  color: $white-color;
  font-size: 1.0625rem;
}

.font-content1 {
  color: $white-color;
  font-size: 1.875rem;
  font-weight: 700;
}
.font-content2 {
  color: $white-color;
  font-size: 0.9375rem;
  font-weight: 501;
}
.font-content3 {
  color: $white-color;
  font-size: 0.8125rem;
  font-weight: 500;
}
.Mon-14 {
  font-size: 1.625rem;
  .text-style-1 {
    font-weight: bold;
    font-size: 1.4375rem;
  }
  button {
    font-size: 1.375rem;
    background-color: $white-color;
    height: 69px;
    border: 0px;
  }
}
.Mon-14 button:hover {
  color: $white-color;
  border-radius: 7px;
  border: solid 1px $color2;
  background-color: $color2;
}

.Mon-14 .btn:hover {
  color: $white-color;
  background-color: $color2;
}

.Rectangle-691 {
  /*arrow box*/
  width: 40px;
  height: 90px;
  border-radius: 3px;
  background-color: $bg-color2;
  padding: 0px 0px 0px 0px;
}

//End for Now
//Interpreter Detail
.seperator2 {
  border-left: 2px solid $color5;
  height: auto;
  padding-left: 20px;
}

.lang-box {
  padding: 12px 10px 14px;
  border-radius: 8px;
  background-color: $light-blue;
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $color1;
}

//TherapistVideoTherapy
.Path-103 {
  width: 495px;
  height: 928px;
  margin: 23px 0 0 49px;
  padding: 34px 35px 33px 33px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  border-radius: 16px;
}

.Rectangle-876 {
  width: 425px;
  height: 618px;
  margin: 30px 2px 36px 0;
  padding: 25px 24px 291px;
  object-fit: contain;
  border-radius: 16px;
  border: solid 1px #bdcbd6;
  background-color: $white-color;
}

.Dr-John-L-Glenn {
  width: 89px;
  height: 16px;
  margin: 0 288px 10px 0;
  font-family: $base-font1;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.25;
  letter-spacing: normal;
  text-align: left;
  color: #373737;
}

.p {
  width: 377px;
  height: 276px;
  margin: 10px 0 0;
  font-family: $base-font1;
  font-size: 0.9375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: left;
  color: #373737;
}

.Path-100 {
  border-radius: 5px;
  margin: 0 23px 23px 0;
  padding: 12px 23.9px 10px 23.6px;
  background-color: $light-blue;
}

.videoimg {
  width: 120%;
  height: 100%;
}

.custom-table-view2 {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  width: 85%;
  margin-left: 10%;
}

//end

//for img
.image {
  @include setThumbinail;
}
.c-thumbnail {
  width: 150px;
  max-width: 100%;
  position: relative;
  border-radius: 50%;
  margin-top: 15px;
  margin-left: 10px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  @include onMobileScreen {
    width: 100px;
    height: 100px;
    margin-top: 35px;
  }
}
.settingsimg {
  width: 196px;
  height: 191px;
  margin: 35px 48px 64px 3px;
  border-radius: 317px;
  object-fit: cover;
  @include onMobileScreen {
    width: 120px;
    height: 120px;
    margin: 20px 40px 64px 0px;
  }
  @include onOnlyThisWidth(1024px) {
    width: 150px;
    height: 150px;
    margin: 20px 40px 64px 0px;
  }
}
.nodatafound {
  margin-left: 30%;
}
.nodatafoundtable {
  margin-left: 70%;
}
.choosefile {
  opacity: 0;
  margin-top: -30%;
  margin-left: -40%;
}
.change {
  margin-top: -55%;
  font-size: 1.125rem;
  margin-left: -10%;
}
.change1 {
  margin-top: -140%;
  font-size: 1.125rem;
  margin-left: -10%;
}
.add-your-ques {
  width: 300px;
  height: 50px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #22dd8f, #11ab6b);
  padding-left: 55px;
  font-family: $base-font1;
  font-size: 1.4375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: $white-color;
  margin-left: 10%;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border: gray 1px solid;
    //   background: white;
  }

  // Box hover
  // &:hover + label:before {
  //   background: $color2;
  // }

  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }

  // Box checked
  &:checked + label:before {
    background: $color2;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 12px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white;
    transform: rotate(45deg);
  }
}
.dob-box {
  @include onMobileScreen {
    width: 80px;
  }
  @include onOnlyThisWidth(1024px) {
    width: 80px;
  }
}
.dash-blue {
  @include onMobileScreen {
    margin-right: 25%;
  }
}
.dash-blue-div {
  @include onMobileScreen {
    margin-left: 20%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 8%;
  }
}

.tenant-logo-img {
  max-width: 40%;
  margin-top: 2%;
}
.tenant-logo-img-header {
  width: 190px;
}
.login-page {
  background-image: linear-gradient(to bottom, #24d5ff, #16c67d);
}
.custom-table-view-login {
  background: #eaf5ff;
  padding: 60px 50px 80px 50px;
  border-radius: 15px;
  width: 90%;
  margin: auto;
  //opacity: 0.9;
}
.rbc-overlay {
  z-index: 9999 !important;
}
.fileuplaod {
  #fileuplaod {
    width: 100%;
    height: 100%;
  }
}
.dashboard-session-cal {
  min-width: 678px;
  .back {
    padding-left: 15px;
    padding-right: 15px;
    h6 {
      font-weight: normal;
    }
  }
}
::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.soap-note-tabs {
  .nav-link.active {
    background-color: #cbcbcb !important;
    color: black !important;
    border: 0px;
    border-bottom: 3px solid $color2 !important;
    font-weight: bold;
    font-family: $base-font1;
  }
  .nav-link {
    font-size: 20px;
    border-radius: 3px 0 0 0 !important;
  }
}

.custom-nav-tabs {
  .nav-tabs {
    border: 0px;
    background: $bg-color2;
    .nav-link.active {
      background-color: $bg-color2;
      color: black;
      border: 0px;
      border-bottom: 3px solid $color2;
      font-weight: bold;
      font-family: $base-font1;
    }
    a {
      color: $color1;
      font-size: 18px;
    }
    .nav-link {
      border-radius: 0px;
    }
  }
  .tab-content {
    border-left: solid 1px $color8;
    border-right: solid 1px $color8;
    border-bottom: solid 1px $color8;
  }
}
