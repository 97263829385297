.c-sidebar {
  @extend .sticky-top;
  align-self: flex-start;
  top: 20px;
}
// sidebar
.sidebar-item {
  padding: 0;
  margin: 0;
  li {
    font-size: 0.9375rem;
    list-style: none;
    @include onMaxWidth(1500px) {
      font-size: 0.8125rem;
    }
    @include onSmallScreen {
      font-size: 0.75rem;
    }
    a {
      padding: 1em;
      display: flex;
      align-items: center;
      text-decoration: none;
      img,
      i {
        height: 1.4em;
        width: 1.4em;
        margin-right: 0.5em;
      }
    }
  }
  span {
    font-family: $base-font2;
    line-height: 1.53;
    text-align: left;
    color: $color1;
  }
  .sidebar-active,
  li:hover {
    border-radius: 6px;
    background-color: $color2;
    @include onMobileScreen {
      width: 120px;
    }
    span {
      color: white;
    }
    .img-fluid {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(106%) contrast(106%);
    }
  }
}

.sidebar-active.dropdown-active {
  background: #dfe8f5 !important;
}

.sidebar-active.dropdown-active span {
  color: #222248 !important;
}

.dropdown-active {
  padding-left: 21px;
}

.sidebar-item .sidebar-active.dropdown-active .img-fluid {
  filter: invert(0) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(106%)
    contrast(106%);
}

.copyright {
  &-image {
    width: 53.8px;
    height: 25.7px;
    position: absolute;
    left: 40px;
    top: 94%;
    &-sidebar {
      width: 40px;
      height: 20px;
      position: fixed;
      left: 20px;
      top: 94%;
      @include onOnlyThisWidth(1024px) {
        left: 10px;
      }
    }
  }
  &-text {
    position: absolute;
    left: 95px;
    top: 94.3%;
    font-family: $base-font2;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $color6;
    &-sidebar {
      position: fixed;
      left: 60px;
      top: 94.1%;
      font-family: Poppins;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $color6;
      @include onOnlyThisWidth(1024px) {
        left: 50px;
      }
      @include onMobileScreen {
        width: 60px;
        overflow-wrap: break-word;
      }
    }
  }
}
