@import "./resources/css/variable";
@import "~bootstrap/scss/bootstrap.scss";
@import "./resources/css/style.scss"; // common css style

@import "./resources/css/custom.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-bg;
  font-family: $base-font1;
  background: #f5f6fa;
}
.container {
  max-width: inherit;
}

body,
:root,
html {
  font-size: 16px;
  @include onMediumScreen {
    font-size: 14px;
  }
}

.border-none {
  border: none !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.p-10 {
  padding: 10px !important;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 5px;
  border-radius: 8px;
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.is-invalid {
  border-color: #dc3545;
}

.required-span {
  color: red;
  vertical-align: text-top;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.small-tenant-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.search-container .btn {
  height: 41px;
  margin-top: 2px;
  padding: 8px;
}

.search-container .btn svg {
  width: 25px;
  height: auto;
}

.form-control {
  font-size: 1rem !important;
  height: 45px !important;
}

.nxt-btn {
  width: 200px;
  margin: 20px 0;
  height: 45px;
}

textarea.form-control {
  height: 100% !important;
}
.clearfix {
  clear: both;
  display: block;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.display-block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.custom-radio {
  margin-right: 2rem;
  margin-left: 23px;
}

.tenant-detail-logo {
  border-radius: 50%;
}

.blue-card {
  background-color: #387af6 !important;
}

.float-right {
  float: right;
}

.btn-view-more {
  width: 100px !important;
}

.nav-tabs.settings-tab .nav-link {
  font-size: 14px !important;
}

.ml--10 {
  margin-left: -10px !important;
}

.ml-10 {
  margin-left: 10px;
}

.btn-theme-red {
  background: #eb591f;
  color: #fff;
}
