.c-forms{
    &--family{
        padding: 20px;
    }
    &--therapist{
        padding-left: 10px;
        padding-right: 10px;
    }
    &__uploader{
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        box-shadow: 0px 0px 6px rgba($black-color, .1);
        // &:not(:last-child){
        //     border-bottom: 1px solid $light-blue;
        // }
    }
    &__upload-btn{
        svg{
            width: 20px;
            height: auto;
        }
    }
    &__title{
        width: 60%;
    }
    &__date{
        width: 40%;
    }
}

// custom forms

.card-lg {
    padding: 20px 0;
    border-radius: 16px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    &__header {
        text-align: center;
        // border-bottom: solid 1px rgba(56, 122, 246, 0.2);
    }
    &__content {
        padding: 40px;
    }
    &__text-underline {
        font-weight: bold;
        text-decoration: underline;
    }
    &__footer {
        // border-top: solid 1px rgba(56, 122, 246, 0.2);
        text-align: center;
    }
    // label {
    //     width: 100% !important;
    // }
    .create-sign-btn {
        width: 200px;
    }
}

.pl-0 {
    padding-left: 0 !important;
}

.float-right{
    float: right;
}

.font-bold {
    font-weight: bold;
}

.mt-10 {
    margin-top: 10px;
}

.mt-30 {
    margin-top: 50px;
}

.mb-10 {
    margin-bottom: 10px;
}

.form-list .input-group .custom-select {
    height: 40px !important;
}

.form-list .submit-btn {
    margin-top: 27px !important;
}