// font family 
$base-font1: 'Roboto', sans-serif;
$base-font2: 'Poppins', sans-serif;

// sizes
$radius1: 3px;
$radius2: 5px;
$radius3: 8px;
$radius4: 12px;
$radius5: 16px;
$radius6: 40px;
$radius7: 52px;

// $grid-gutter-width: var(--gutter-width, 15px);

// color variable
$body-bg:#f5f6fa;
$btn-green: #0fd683;
$btn-yellow: #ffd629;

$bg-color1: #f7f9fd;
$bg-color2: #eaf1fe;
$bg-color3: #e0e6f2;
$white-color: #ffffff;
$black-color: #000000;
$color1: #222248;
$color2: #387af6; // sidebar active color
$color3: #7f9aaf;
$color4: #e8f1ff;
$color5: #414141;
$color6: #191919;
$color7: #b7b7b7;
$color8: #97b7de;
$light-blue-2: #e1eafc;
$light-blue: #d8e4f1;
$light-cyan: #e5ffff; //#ccffff;

// status colors
$s-complete: $color2;
$s-approve: #e2f6e9;
$s-pending: #ffcecc;
$s-missed: #f5f59b;
$s-cancelled: #d93025;

$statusList: 'complete', 'approve', 'pending', 'missed', 'cancelled';

$theme-colors: (
  "danger": #FC341D,
  "theme-blue": $color2,
  "theme-light-blue": $light-blue,
  "theme-light-red": #ffcecc,
  "complete": $color2,
  "approve": #e2f6e9,
  "pending": #ffcecc,
  "missed": #f5f59b,
  "cancelled": #d93025,
  "light-cyan": $light-cyan
)